// WARNING: This file is automatically generated. Do not edit.
import * as Types from "../../../../api/gql/main/types.generated";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type GetCountriesForWebQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.CountryWhereInput>;
}>;

export type GetCountriesForWebQuery = {
  __typename?: "Query";
  getCountriesForWeb: Array<{
    __typename?: "Country";
    id: string;
    code: string;
    lon?: number | null;
    lat?: number | null;
    name: string;
    nameAr: string;
    callingCode: string;
    currencyCode: Types.CurrencyCode;
  }>;
};

export const GetCountriesForWebDocument = gql`
  query GetCountriesForWeb($where: CountryWhereInput) {
    getCountriesForWeb(where: $where) {
      id
      code
      lon
      lat
      name
      nameAr
      callingCode
      currencyCode
    }
  }
`;

/**
 * __useGetCountriesForWebQuery__
 *
 * To run a query within a React component, call `useGetCountriesForWebQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountriesForWebQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountriesForWebQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetCountriesForWebQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCountriesForWebQuery,
    GetCountriesForWebQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCountriesForWebQuery,
    GetCountriesForWebQueryVariables
  >(GetCountriesForWebDocument, options);
}
export function useGetCountriesForWebLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCountriesForWebQuery,
    GetCountriesForWebQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCountriesForWebQuery,
    GetCountriesForWebQueryVariables
  >(GetCountriesForWebDocument, options);
}
export type GetCountriesForWebQueryHookResult = ReturnType<
  typeof useGetCountriesForWebQuery
>;
export type GetCountriesForWebLazyQueryHookResult = ReturnType<
  typeof useGetCountriesForWebLazyQuery
>;
export type GetCountriesForWebQueryResult = Apollo.QueryResult<
  GetCountriesForWebQuery,
  GetCountriesForWebQueryVariables
>;
